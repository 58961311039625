import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import calories from "../../assets/calories.png";
import {motion} from 'framer-motion'

const Hero = () => {
  const transition ={type: 'spring',duration :3}
  const mobile = window.innerWidth<=768 ? true:false;
  return (
    <div className="hero" id='home'>
         <div className="blur hero-blur"></div>

      <div className="left-h"> 
      <Header/>
      <div className="the-best-ad">
         <motion.div
           
           
          initial={{left: mobile?"160px":'238px'}}
          whileInView={{left:'9px'}}
          transition={{...transition, type: 'tween'}}
         ></motion.div>
        <div></div>
        <span>the best fitness club in the town</span>
      </div>
    {/* heroheading*/}
     <div className="hero-text">
     <div>
       <span className='stroke-text'>shape  </span>
       <span>your </span>
       </div>
       <div>
       <span>ideal body</span></div>
       <div className="">
        <span> 
       In here we will help you to shape and build your ideal body and live up your life to fullest</span></div>
       
       </div>
      {/*figures */}
      <div className="figures">
        <div>
          <span>+140</span>
          <span>EXPERT COACHES</span>
        </div>
        <div>
          <span>+ 978</span>
          <span>MEMBERS JOINED</span>
        </div>
        <div>
          <span>+ 50</span>
          <span>FITNESS PROGRAMS</span>
        </div>
      </div>
      {/*hero button */}
      <div className="hero-buttons">
        <button className="btn">Get started</button>
        <button className="btn">Learn More</button>
      </div>
       </div>
      <div className="right-h"> 
    <button className='btn'>join now</button>
     <motion.div
      initial={{right: "-1rem"}}
      whileInView={{right:"4rem"}}
     transition={transition}
     className="heart-rate">
      <img src={Heart} alt="" />
      <span>Heart Rate</span>
      <span>116 bpm</span>
     </motion.div>
{/* hero imagesss */}
<img src={hero_image} alt="" className="hero-image"/>
<img src={hero_image_back} alt="" className="hero-image-back"/>
{/*calories */}
<div className="calories">
  <img src={calories} alt="" />
  <div>
  <span>Calories burned</span>
  <span>220 kcal</span>
  </div>
</div>
    </div>
  </div>
  );
};

export default Hero
